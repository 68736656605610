<script>
  import CInterpretationStats from '@/components/grid/cells/shared/CInterpretationStats';

  export default {
    extends: CInterpretationStats,
    computed: {
      stats() { return this.propData?.statistics; },
    }
  };
</script>
