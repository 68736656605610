(<template>
  <div class="interpretation-skills">
    <div class="interpretation-skills__header">
      <div class="interpretation-skills__header--left">
        <h1 class="interpretation-skills__header-title">{{ $gettext('Interpretation Skills') }}</h1>
      </div>
      <div>
        <button class="sk-btn sk-btn--white interpretation-skills__header-create-button"
                @click="openCreateSkillModal">{{ $gettext('Add skill') }}</button>
      </div>
    </div>
    <div class="interpretation-skills__cont">

      <!------- All Tabs ------->
      <div class="interpretation-skills-tabs__cont">
        <div class="interpretation-skills__wrapper">
          <multiselect-tab v-for="tab in leftSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.selectedList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :tab="tab" />
        </div>
        <div class="interpretation-skills__wrapper">
          <multiselect-tab v-for="tab in rightSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.selectedList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :left-side-icon="tab.leftSideIcon"
                           :tab="tab" />
        </div>
      </div>
      <!------- Grid Component ------->
      <ag-grid :column-defs="columnDefs"
               :grid-options="gridOptions"
               :fetch-data-func="fetchDataFunc"
               :grid-data="gridData"
               @interface="getChildInterface" />
    </div>
  </div>
</template>)

<script>
/* eslint-disable vue/no-unused-components */
// For Grid filter components
  import CustomerNameFilter from '@/components/grid/filters/shared/TextFilter';
  import LocationFilter from '@/components/grid/filters/LocationFilter';
  import StandbyFilter from '@/components/grid/filters/StandbyFilter';
  import GenderFilter from '@/components/grid/filters/GenderFilter';
  import IdFilter from '@/components/grid/filters/IdFilter';
  import PageWithGridMixin from '@/mixins/PageWithGridMixin';
  // ---- Cells ----//
  import CId from '@/components/grid/cells/shared/CId';
  import CInterpreter from '@/components/grid/cells/skill/interpretation/CInterpreter';
  import CNotes from '@/components/grid/cells/skill/CNotes';
  import CLocation from '@/components/grid/cells/skill/interpretation/CLocation';
  import CStatus from '@/components/grid/cells/skill/interpretation/CStatus';
  import CGender from '@/components/grid/cells/shared/CGender';
  import CStandBy from '@/components/grid/cells/skill/interpretation/CStandBy';
  import CStats from '@/components/grid/cells/skill/interpretation/CStats';
  import {mapState} from 'vuex';
  import CSkillInfo from '@/components/grid/cells/skill/interpretation/CSkillInfo';
  import LanguagePairFilter from '@/components/grid/filters/shared/LanguagePairFilter';
  import QualificationFilter from '@/components/grid/filters/shared/QualificationFilter';
  import CCreatedAt from '@/components/grid/cells/skill/CCreatedAt';

  export default {
    components: {
      'person-filter': CustomerNameFilter,
      'location-filter': LocationFilter,
      'gender-filter': GenderFilter,
      'standby-filter': StandbyFilter,
      'id-filter': IdFilter,
      'language-pair-filter': LanguagePairFilter,
      'qualification-filter': QualificationFilter,
      'c-id': CId,
      'c-skill-info': CSkillInfo,
      'c-interpreter': CInterpreter,
      'c-interpretation-stats': CStats,
      'c-gender': CGender,
      'c-standby': CStandBy,
      'c-notes': CNotes,
      'c-location': CLocation,
      'c-status': CStatus,
      'c-created-at': CCreatedAt,
      'ag-grid': () => import('@/components/grid/GridComponent')
    },
    mixins: [PageWithGridMixin],
    data() {
      return {
        currentView: this.$route.query.view || 'approved',
        currentPage: Number(this.$route.query.page) || 1,
        items: this.$store.state.filterItemsAmount || 10,
        ransackParams: {},
        fetchGridDataAction: 'AllInterpretationSkillsStore/getInterpreterSkills',
        gridOptions: {
          pagination: true,
          suppressPaginationPanel: true,
          suppressMovableColumns: true,
          suppressColumnVirtualisation: false,
          paginationPageSize: this.$store.state.filterItemsAmount,
          paginationAutoPageSize: false,
          suppressExcelExport: true,
          suppressRowClickSelection: true,
          rowHeight: 65,
          popupParent: null,
          rowStyle: {'max-height': '58px'},
          rowModelType: 'infinite',
          // ------ cacheBlockSize Equal to pageSize no caching ------- //
          cacheBlockSize: this.$store.state.filterItemsAmount,
          maxBlocksInCache: 1,
          maxConcurrentDatasourceRequests: 2,
          domLayout: 'autoHeight',
          floatingFiltersHeight: 60
        },
        // ------- Tabs Data ------- //
        tabForUpdate: ''
      };
    },
    computed: {
      ...mapState('AllInterpretationSkillsStore', {
        interpreterSkillsData: (state) => state.interpreterSkillsData || {},
        updateInterpreterSkillPageGrid: (state) => state.updateInterpreterSkillPageGrid || {},
      }),
      statistics() { return this.interpreterSkillsData.statistics; },
      approvedCount() { return this.statistics?.approved; },
      learningCount() { return this.statistics?.learning; },
      pendingCount() { return this.statistics?.pending; },
      declinedCount() { return this.statistics?.declined; },
      allCount() { return this.statistics?.all; },
      anniversariesCount() { return this.statistics?.anniversary; },
      columnDefs() {
        return [
          {
            headerName: this.$gettext('ID'),
            field: 'id',
            maxWidth: 140,
            flex: 1,
            sortable: true,
            floatingFilterComponent: 'id-filter',
            ...this.textFilterNoMenu,
            cellRendererSelector: (params) => {
              return {
                component: 'c-id',
                params: params
              };
            }
          },
          {headerName: this.$gettext('Created at'),
           maxWidth: 150,
           flex: 1,
           field: 'createdAt',
           suppressMenu: true,
           filter: 'agDateColumnFilter',
           valueFormatter: this.dateFormatter,
           filterParams: this.dateFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-created-at',
               params: params
             };
           }},
          {headerName: this.$gettext('Language, qualification and status'),
           field: 'langId',
           flex: 2,
           floatingFilterComponent: 'language-pair-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-skill-info',
               params: params
             };
           }},
          {headerName: this.$gettext('Name, email, phone'),
           field: 'lookupFields',
           flex: 2,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'person-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-interpreter',
               params: params
             };
           }},
          {headerName: this.$gettext('Status'),
           field: 'qualificationId',
           flex: 1,
           floatingFilterComponent: 'qualification-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-status',
               params: params
             };
           }},
          {headerName: this.$gettext('Gender'),
           field: 'sex',
           flex: 1,
           ...this.multiSelectFilterParams,
           floatingFilterComponent: 'gender-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-gender',
               params: params
             };
           }},
          {headerName: this.$gettext('Location'),
           field: 'location',
           flex: 2,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'location-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-location',
               params: params
             };
           }},
          {headerName: this.$gettext('Standby'),
           field: 'interpreterCanStandby',
           maxWidth: 120,
           flex: 1,
           ...this.multiSelectFilterParams,
           floatingFilterComponent: 'standby-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-standby',
               params: params
             };
           }},
          {headerName: this.$gettext('Interpretation stats'),
           field: 'interpretationStats',
           minWidth: 120,
           flex: 1,
           cellRendererSelector: (params) => {
             return {
               component: 'c-interpretation-stats',
               params: params
             };
           }},
          {headerName: this.$gettext('Notes'),
           minWidth: 300,
           maxWidth: 350,
           flex: 2,
           field: 'notes',
           cellRendererSelector: (params) => {
             return {
               component: 'c-notes',
               params: params
             };
           }},
        ];
      },
      gridData() {
        return {
          page: this.currentPage,
          items: this.items,
          ransackParams: this.tabRansackParams
        };
      },
      tabRansackParams() {
        switch (this.currentView) {
          case 'approved':
            return {
              's[status_eq]': 30,
            };
          case 'learning':
            return {
              's[status_eq]': 20
            };
          case 'pending':
            return {
              's[status_eq]': 10
            };
          case 'declined':
            return {
              's[status_eq]': 40
            };
          case 'anniversaries':
            return {
              's[with_anniversary_today]': true
            };
          case 'all':
            return {};
        }
      },
      isApprovedTab() { return this.currentView === 'approved'; },
      isLearningTab() { return this.currentView === 'learning'; },
      isPendingTab() { return this.currentView === 'pending'; },
      isDeclinedTab() { return this.currentView === 'declined'; },
      isAllView() { return this.currentView === 'all'; },
      isAnniversariesView() { return this.currentView === 'anniversaries'; },
      leftSideTabs() {
        return [
          {
            tab: 'approved',
            isActive: this.isApprovedTab,
            title: this.$gettext('Approved'),
            count: this.approvedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'learning',
            isActive: this.isLearningTab,
            title: this.$gettext('Learning'),
            count: this.learningCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'pending',
            isActive: this.isPendingTab,
            title: this.$gettext('Pending'),
            count: this.pendingCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          }, {
            tab: 'declined',
            isActive: this.isDeclinedTab,
            title: this.$gettext('Declined'),
            count: this.declinedCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          },
          {
            tab: 'all',
            isActive: this.isAllView,
            title: this.$gettext('All'),
            count: this.allCount,
            onClick: this.setCurrentView
          }
        ];
      },
      rightSideTabs() {
        return [
          {
            tab: 'anniversaries',
            isActive: this.isAnniversariesView,
            title: this.$gettext('Anniversaries'),
            count: this.anniversariesCount,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            leftSideIcon: 'anniversary'
          }
        ];
      }
    },
    watch: {
      updateInterpreterSkillPageGrid() {
        this.updateGridData();
      }
    },
    methods: {
      openCreateSkillModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'create-inter-skill-modal',
          classes: ['sk-modal--new', 'sk-modal--customer-actions'],
          width: 540,
          data: {
            title: this.$gettext('Add skill'),
            context: this,
            successHandlingCallbackName: 'updateGridData'
          }
        });
      }
    },
    beforeRouteEnter(to, from, next) {
      if (to.query.view == {} || to.query.view == null || to.query.view == undefined) {
        next((vm) => {
          return {path: to.path, query: {view: 'approved', page: 1, items: vm.$store.state.filterItemsAmount}};
        });
      }
      next();
    },
    childInterface: {
      updateData: () => {}
    },
  };
</script>
<style scoped>
.interpretation-skills {
  margin: 10px 20px;
}

.interpretation-skills__header-create-button {
  padding-right: 15px;
  padding-left: 40px;
  border: 1px solid #d3d5db;
  background-color: #fff;
  background-image: var(--plus-icon);
  background-position: 5.55% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
}

.interpretation-skills__cont {
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);
}

.interpretation-skills-tabs__cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #d3d5de;
}

.interpretation-skills__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.interpretation-skills__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.interpretation-skills__header--left {
  display: flex;
}

.interpretation-skills__header-title {
  font-size: 24px;
}
</style>
