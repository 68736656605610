<template>
  <div class="location">
    <p class="overflow-ellipsis"
       @mouseleave="closeTooltip"
       @mouseover="openTooltip(location, $event)">{{ location }}</p>
  </div>
</template>

<script>
  import helpers from '@/helpers';
  import ToolTipMixin from '@/mixins/ToolTipMixin';

  export default {
    mixins: [ToolTipMixin],
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      location() { return (this.propData?.location && Object.keys(this.propData?.location).length > 0) ? helpers.getFullAddress(this.propData?.location) : '-'; }
    }
  };
</script>

<style scoped>
.location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
</style>
