<script>
  import CStandBy from '@/components/grid/cells/shared/CStandBy';

  export default {
    extends: CStandBy,
    computed: {
      standBy() { return this.propData?.standby === 'yes'; },
      standByPlus() { return this.propData?.standby === 'plus'; },
      standByStatus() {
        if (this.standByPlus) return this.$gettext('Plus');
        else if (this.standBy) return this.$gettext('Yes');
        else return '-';
      },
    }
  };
</script>
